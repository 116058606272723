import {
  ERichTextDefaultTag,
  Gallery,
  EGalleryTheme,
  toEnum,
  toImageProps,
  toRichText,
} from '@front/shared/ds';
import { Section } from '@shared/master-types';
import React from 'react';

export type TGalleryConnectedProps = Extract<
  NonNullable<Section['rows']>[number],
  { blockType: 'gallery-2' }
>;

const GalleryConnected: React.FC<TGalleryConnectedProps> = ({
  richTitle,
  tabs,
  theme,
  titleIcons = [],
}) => {
  const preparedTabs = tabs?.map(
    ({ title, description, imageDesktop, imageTablet, imageMobile }) => ({
      title: title,
      imageDesktop: toImageProps(imageDesktop) || undefined,
      imageTablet: toImageProps(imageTablet) || undefined,
      imageMobile: toImageProps(imageMobile) || undefined,
      description: toRichText(description, { hasWrapper: false }),
    }),
  );
  const preparedTitle = toRichText(richTitle, {
    defaultTag: ERichTextDefaultTag.Span,
    hasWrapper: false,
  });
  const preparedTitleIcons = titleIcons.map(({ icon }) => toImageProps(icon));
  return (
    <Gallery
      title={preparedTitle}
      tabs={preparedTabs}
      theme={toEnum(EGalleryTheme, theme)}
      titleIcons={preparedTitleIcons}
    />
  );
};

export default GalleryConnected;
